import { Routes } from '@angular/router';
import { CoordinatorComponent } from './coordinator/coordinator.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { InspectorComponent } from './inspector/inspector.component';
import { SessionEndComponent } from './session-end/session-end.component';
import { ZoomComponent } from './zoom/zoom.component';
import { AuthGuard } from './guards/auth.guard';
import { InspectorTestComponent } from './inspector-test/inspector-test.component';

export const routes: Routes = [
  { path: 'coordinator/:sessionId', component: CoordinatorComponent, canActivate: [AuthGuard] },
  { path: 'inspector/:sessionId', component: InspectorComponent, canActivate: [AuthGuard] },
  { path: 'session-end', component: SessionEndComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'govideo', component: ZoomComponent },
  { path: 'inspector-test/:sessionId', component: InspectorTestComponent },
  { path: '**', redirectTo: '/contact-us', pathMatch: 'full' },
  { path: '', redirectTo: '/contact-us', pathMatch: 'full' }
];