<div #inspectorVideoContainer class="video-container">
  <video
    #inspectorLocalVideo
    preload="metadata"
    autoplay
    style="display: none"
  ></video>

</div>
<div class="controls">
    <button pButton type="button" label="Start Video" (click)="startVideo($event)"></button>
    <button pButton type="button" label="End Session" (click)="endSession()"></button>
    <button pButton type="button" label="Test" (click)="test()"></button>
</div>
