import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ZoomClientType, ZoomService } from '../services/zoom.service';
import { ChangeDetectorRef } from '@angular/core';
import { ParticipantPropertiesPayload, VideoQuality } from '@zoom/videosdk';
import { ButtonModule } from 'primeng/button';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ConfirmationService, MessageService, Message } from 'primeng/api';
import { ConfirmDialog } from 'primeng/confirmdialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AuthService } from '../services/auth.service';
import { CommandEvent, CommandEventType } from '../services/zoom.service';
import { MediaService } from '../services/media.service';
import { DeviceService } from '../services/device.service';
import { SessionService } from '../services/session.service';
import { NotificationService } from '../services/notification.service'; // Import the new notification service

interface ImageSettingsInterface {
  quality: number,
  height: number,
  width: number,
  thumbnailHeight: number
}

@Component({
  selector: 'app-inspector',
  templateUrl: './inspector.component.html',
  styleUrls: ['./inspector.component.scss'],
  standalone: true,
  imports: [CommonModule, ButtonModule, ConfirmDialogModule],
})
export class InspectorComponent implements OnInit, OnDestroy {
  @ViewChild('inspectorVideoContainer', { static: true })
  inspectorVideoContainer!: ElementRef<HTMLVideoElement>;
  @ViewChild('inspectorLocalVideo', { static: true })
  inspectorLocalVideo!: ElementRef<HTMLVideoElement>;
  sessionId: string = '';
  zoomClient: ZoomClientType | void | null = null;
  videoQuality = VideoQuality.Video_1080P;
  sessionEnded = false;
  localVideoStream: MediaStream | null = null;

  constructor(
    private route: ActivatedRoute,
    private zoomService: ZoomService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private authService: AuthService,
    private mediaService: MediaService,
    private deviceService: DeviceService,
    private sessionService: SessionService,
    private notify: NotificationService,
  ) { }

  async test() {
    this.mediaService.capturePhoto('20241001a').then((imageObjectUrl: string) => {
      console.log('Image captured: ', imageObjectUrl);
    });
    // if (!this.zoomService.inspector || !this.zoomService.coordinator) {
    //   throw new Error('Inspector or coordinator not found');
    // }
    // this.micDevices = await this.zoomService.getMicList();
  //   const commandEvent: CommandEvent = {
  //     type: CommandEventType.COORDINATOR_TEST,
  //     data: null,
  //   }   
  //   const commandResponse = await this.zoomService.sendCommand(commandEvent, this.zoomService.coordinator.userId);
  //   console.log(commandResponse);
  }

  async ngOnInit(): Promise<void> {
    this.sessionId = this.route.snapshot.paramMap.get('sessionId') || '';
    this.notify.showToast('info', 'GoVideo', 'Connecting to media network...');
    if (this.sessionId) {
      const authorized = this.authService.authorize(this.sessionId);
      if (authorized && typeof authorized.role === 'number' && authorized.userIdentity && authorized.sessionId) {
        this.zoomClient = await this.zoomService.connect(authorized.sessionId, authorized.userIdentity, authorized.role);
        if (!this.zoomClient) {
          this.notify.showToast('error', 'GoVideo', 'Failed to connect to media network.', 0);
          this.router.navigate(['/contact-us']);
        } else {
          this.setupEventListeners();
          this.notify.showToast('success', 'GoVideo', 'Connected to media network.');
        }
      } else {
        this.notify.showToast('error', 'GoVideo', 'Not authorized to access this session.', 0);
        this.router.navigate(['/contact-us']);
      }
    } else {
      this.router.navigate(['/contact-us']);
    }
  }

  ngOnDestroy(): void {
    if (!this.sessionEnded) {
      const user = this.zoomClient?.getCurrentUserInfo() || null;
      this.zoomService.leave();
      this.zoomService.destroyClient();
    }
  }

  // async initZoom() {
  //   const userName = 'Inspector';
  //   this.cdr.detach();
  //   this.zoomClient = await this.zoomService.connect(this.sessionId, userName, 1);
  //   this.cdr.reattach();
  //   this.cdr.detectChanges();
  // }

  setupEventListeners() {
    if (!this.zoomService.client) {
      throw new Error('Zoom client not initialized');
    }
    this.zoomService.client.on('peer-video-state-change', (payload: { action: "Start" | "Stop"; userId: number }) => {
      console.log('Peer video state change');
      const participant = this.zoomService?.client?.getUser(payload.userId);
      console.log("Participant:", participant);
      if (payload.action === 'Start') {
        console.log('Peer state change event - Start video');
        if (participant?.userIdentity === "coordinator") {
          // this.cdr.detach();
          // this.startCoordinatorVideo(participant);
          // this.cdr.reattach();
          // this.cdr.detectChanges();
        }
      } else {
        console.log('Peer state change event - Stop video');
        this.notify.showToast('info', 'GoVideo', 'Coordinator has stopped the video');
      }
    });

    this.zoomService.client.on('user-added', (payload: ParticipantPropertiesPayload) => {
      this.notify.showToast('info', 'GoVideo', 'Coordinator has joined the session');
      console.log('Participant joined event :', payload);
    });

    this.zoomService.client.on('user-removed', (payload: ParticipantPropertiesPayload) => {
      this.notify.showToast('info', 'GoVideo', 'Coordinator has left the session');
      console.log('Participant left event:', payload);
    });

    this.zoomService.client.on('session-ended', () => {
      this.sessionEnded = true;
      this.notify.showToast('warn', 'GoVideo', 'Session has ended');
      console.log('Session ended');
    });

    this.zoomService.client.on('error', (error: Error) => {
      console.error('Zoom client error:', error);
      this.notify.showToast('error', 'GoVideo', 'An error occurred while connecting to the media network.', 0);
    });

    this.zoomService.client.on('zoom-sdk-ready', () => {
      console.log('Zoom SDK ready');
      // this.startVideo();
    });

  }

  async startVideo(event: any): Promise<void> {
    try {
      if (!this.zoomClient) {
        throw new Error('Zoom client not initialized');
      }

      this.cdr.detach();
      
      console.log('Orientation: ', this.deviceService.orientation$());

      let cameraId = await this.getEnvironmentCameraDeviceId();
      if (!cameraId) {
        const cameras: any = await this.deviceService.getVideoDevices(); //stream.getCameraList();
        // const cameraId = await this.getCameraId();
        // if (!cameraId) {
        //   throw new Error('No cameras available');
        // }
        
        // let cameraId = '';
        cameraId = cameras[0]?.deviceId || null;
        // const cameraId = this.deviceService.isMobile() ? 'environment' : cameras[0]?.deviceId ;
      }

      if (!cameraId) {
        throw new Error('No suitible camera was found');
      }
      // // determine the default camera highest resolution for image capture
      // const localStream = await navigator.mediaDevices.getUserMedia({
      //   video: { deviceId: { exact: cameraId }}//this.deviceService.isMobile() ? 'environment' : cameraId } }
      // });

      // const tracks = localStream.getTracks();
      // const track = tracks[0];
      // const capabilities = track.getCapabilities();
      
      // tracks.forEach((track: MediaStreamTrack) => {
      //   track.stop();
      // });

      
      
      // console.log('Camera Capabilities:', capabilities);
      // const highestResolutions = this.calculateHighestResolution(capabilities);
      // const resolution = highestResolutions.find((resolution: any) => resolution.aspectRatio === '1:1');

      // get camera orientation for proper width height ratio


      let maxWidth = 0;
      let maxHeight = 0;

      // let testStream: MediaStream|null = null;
      // for (const resolution of highestResolutions) {
      //   try {
      //     testStream = await navigator.mediaDevices.getUserMedia({
      //       video: { 
      //         deviceId: { exact: cameraId },
      //         width: { min: resolution.width },
      //         height: { min: resolution.height },
      //       }
      //     });
      //     maxWidth = resolution.width;
      //     maxHeight = resolution.height;
      //     break;
      //   } catch (error) {
      //     console.log('Unable to set resolution:', resolution);
      //     continue;
      //   } finally {
      //     if (testStream) {
      //       testStream?.getTracks().forEach((track: MediaStreamTrack) => track.stop());
      //       testStream = null;
      //     }
      //   }
      // }

      // if resolution is higher than full hd then use full hd resolution
      // if (maxWidth >= 2160 && maxHeight >= 1080) {
      //   maxWidth = 2160;
      //   maxHeight = 1080;
      // }

      const maxRes = await this.getMaxResolution(cameraId);

      maxWidth = 2160;
      maxHeight = 1080;

      const stream = this.zoomClient.getMediaStream();
      if (!stream) {
        throw new Error('Media stream not found');
      }

      await stream.startAudio();
      await stream
        .startVideo({
          fullHd: true,
          cameraId: cameraId, //this.deviceService.isMobile() ? 'environment' : cameraId,
          // captureWidth: maxWidth,
          // captureHeight: maxHeight,
          //   // fps: constraints.frameRate
        })
        .then(() => {
          stream
            .attachVideo(
              this.zoomClient!.getCurrentUserInfo().userId,
              VideoQuality.Video_1080P
            )
            .then((el: any) => {
              this.inspectorVideoContainer.nativeElement.appendChild(el);

              const video = el.children[0] as HTMLVideoElement;

              this.mediaService.inspectorVideoElement = video;
            });
        });

      // this.startVideoWithMaxResolution(inspectorVideo);

      // stream.updateSharingCanvasDimension(maxWidth, maxHeight);


      console.log('Video started');




      this.mediaService.inspectorLocalVideoElement =
        this.inspectorLocalVideo.nativeElement;



      // await stream.renderVideo( inspectorVideo, this.zoomClient.getCurrentUserInfo().userId, 640, 360, 0, 0, this.videoQuality);  
      console.log('Inspector video and audio started');
    } catch (error: any) {
      if (error.type !== 'INVALID_OPERATION' || error.reason !== 'Video is started') {
        console.error('Error starting inspector video and audio', error);
      } else {
        console.log('Inspector video is already started');
      }
    } finally {
      this.cdr.reattach();
      this.cdr.detectChanges();
    }
  }

  async endSession() {
    this.cdr.detach();
    const user = this.zoomClient?.getCurrentUserInfo() || null;
    await this.zoomService.leave();
    this.zoomService.destroyClient();
    this.sessionEnded = false;
    this.cdr.reattach();
    console.log('Inspector session ended');
    this.router.navigate(['/session-end']);
  }

  public createImageFromCanvas(video: HTMLVideoElement,
    imagesDiv: HTMLDivElement,
    imageId: string,
    settings: ImageSettingsInterface): Promise<Blob | void> {

    try {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const contentType = 'image/jpeg';

      // adjust dimenstions based on orientation
      let height = (video.videoWidth >= video.videoHeight) ? settings.height : settings.width;
      let width = (video.videoWidth >= video.videoHeight) ? settings.width : settings.height;

      // preserve aspect ratio and avoid upscaling / supersampling
      height = Math.min(height, video.videoHeight);
      width = Math.min(width, video.videoWidth);

      canvas.height = height;
      canvas.width = width;
      const canvasStats = {
        'canvasHeight': canvas.height,
        'canvasWidth': canvas.width
      };
      console.log(canvasStats);
      ctx?.drawImage(video, 0, 0, width, height);
      return new Promise((resolve, reject) => {
        // this.logger.trace(`Converting canvas to blob in createImageFromCanvas() / return new Promise().`);
        canvas.toBlob((blob) => {
          if (blob === null) {
            console.log("Failed to convert canvas to blob");
            reject();
          }
          // const imageUrl = URL.createObjectURL(blob);
          // this.appendImage(imagesDiv, imageId, imageUrl, settings.thumbnailHeight);
          // this.logger.trace(`Successfully converted canvas to blob in createImageFromCanvas() / resolve(blob).`)
          if (blob) {
            resolve(blob);
          } else {
            reject();
          }
        }, contentType, settings.quality);
      });
    } catch (e: any) {
      // this.logger.error(`Exception caught in createImageFromCanvas()`, { errorMessage: e.message, error: e });
      return Promise.reject();
    }
  }

  // async getMaxResolutionForCamera() {
  //   try {
  //     // Get the list of available cameras
  //     const cameras = await navigator.mediaDevices.enumerateDevices();
  //     const videoInputDevices = cameras.filter(device => device.kind === 'videoinput');
  //     const cameraId = videoInputDevices[0]?.deviceId; // Use the appropriate camera ID
  
  //     if (!cameraId) {
  //       throw new Error('No camera found');
  //     }
  
  //     // Get the media stream for the selected camera
  //     const stream = await navigator.mediaDevices.getUserMedia({
  //       video: { deviceId: { exact: cameraId } }
  //     });
  
  //     // Get the video track from the media stream
  //     const videoTrack = stream.getVideoTracks()[0];
  
  //     // Get the capabilities of the video track
  //     const capabilities = videoTrack.getCapabilities();
  
  //     // Extract the maximum resolution
  //     const maxWidth = capabilities.width?.max;
  //     const maxHeight = capabilities.height?.max;
  
  //     console.log(`Max resolution for camera: ${maxWidth}x${maxHeight}`);
  
  //     // Apply constraints for the maximum resolution
  //     const constraints = {
  //       width: maxWidth,
  //       height: maxHeight,
  //       frameRate: 30 // Optional: Desired frame rate
  //     };
  
  //     // Apply the constraints to the video track
  //     await videoTrack.applyConstraints(constraints);
  
  //     // Use the stream with the applied constraints
  //     // For example, attach it to a video element
  //     const videoElement = document.querySelector('video');
  //     if (videoElement) {
  //       videoElement.srcObject = stream;
  //     }
  //   } catch (error) {
  //     console.error('Error getting max resolution for camera:', error);
  //   }
  // } 

  calculateHighestResolution(capabilities: any) {
    const { width, height, aspectRatio } = capabilities;
  
    // Standard aspect ratios to consider
    const aspectRatios = [
      { ratio: 16 / 9, label: '16:9' },
      { ratio: 4 / 3, label: '4:3' },
      { ratio: 1, label: '1:1' }
    ];
  
    const highestResolutions: any = [];
  
    aspectRatios.forEach(({ ratio, label }) => {
      // Calculate the maximum possible width and height for this aspect ratio
      let maxWidth = Math.min(width.max, height.max * ratio);
      let maxHeight = Math.min(height.max, width.max / ratio);
  
      // Round down to the nearest integer
      maxWidth = Math.floor(maxWidth);
      maxHeight = Math.floor(maxHeight);
  
      // Check if the aspect ratio is within the capabilities
      const calculatedAspectRatio = maxWidth / maxHeight;
  
      if (
        calculatedAspectRatio >= aspectRatio.min &&
        calculatedAspectRatio <= aspectRatio.max &&
        maxWidth >= width.min &&
        maxHeight >= height.min
      ) {
        highestResolutions.push({
          width: maxWidth,
          height: maxHeight,
          aspectRatio: label
        });
      }
    });
  
    // sort the resolutions from highest to lowest
    highestResolutions.sort((a: any, b: any) => b.width * b.height - a.width * a.height);

    return highestResolutions;
  }

  isMobileDevice() {
    return /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
  }
  
  async getCameraId() {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true });
      const videoDevices = await navigator.mediaDevices.enumerateDevices();
  
      if (this.isMobileDevice()) {
      //   const environmentCamera = videoDevices.find(
      //     (device) => device.kind === 'videoinput' && device.label.toLowerCase().includes("environment")
      //   );

      //   if (environmentCamera) {
      //     return environmentCamera.deviceId;
      //   }
        return 'environment';
      }
  
      const defaultCamera = videoDevices.find((device) => device.kind === 'videoinput');
      return defaultCamera ? defaultCamera.deviceId : null;
    } catch (error) {
      console.error('Error getting cameraId:', error);
      return null;
    }
  }

  async getMaxResolution(cameraId: string) {
    try {
      const constraints = {
        video: {
          deviceId: cameraId,
          width: { ideal: 2160 },
          height: { ideal: 1080 },
        }
      };
  
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      const videoTrack = stream.getVideoTracks()[0];
      const settings = videoTrack.getSettings();
  
      const maxWidth = settings.width || 2160;
      const maxHeight = settings.height || 1080;
  
      videoTrack.stop();
  
      return { maxWidth, maxHeight };
    } catch (error) {
      console.error('Error getting max resolution:', error);
      return { maxWidth: 2160, maxHeight: 1080 };
    }
  }

  async startVideoWithMaxResolution(videoElement: HTMLVideoElement) {
    try {
      const cameraId = await this.getCameraId();
      if (!cameraId) {
        throw new Error('No camera found.');
      }
  
      const { maxWidth, maxHeight } = await this.getMaxResolution(cameraId);
  
      const stream = this.zoomClient?.getMediaStream();
      
      if (stream) {
        await stream.startVideo({
          videoElement: videoElement,
          hd: true,
          cameraId: cameraId,
          captureWidth: maxWidth,
          captureHeight: maxHeight,
        });

        stream.updateSharingCanvasDimension(maxWidth, maxHeight);

      }
    } catch (error) {
      console.error('Error starting video with max resolution:', error);
    }
  }

  async getEnvironmentCameraDeviceId(): Promise<string | null> {
    try {

      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: { exact: 'environment' } },
      });
  
      const track = stream.getVideoTracks()[0];
      const settings = track.getSettings();
      const deviceId = settings.deviceId;
  
      stream.getTracks().forEach((t) => t.stop());

      console.log('Environment Camera Device ID:', deviceId);
      return deviceId || null;
    } catch (err) {
      console.error('Error accessing environment camera:', err);
      return null;
    }
  }

}