import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ZoomClientType, ZoomService } from '../services/zoom.service';
import { ChangeDetectorRef } from '@angular/core';
import { CaptureVideoOption, ExecutedFailure, ExecutedResult, ParticipantPropertiesPayload, VideoQuality } from '@zoom/videosdk';
import { ButtonModule } from 'primeng/button';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ConfirmationService, MessageService, Message } from 'primeng/api';
import { ConfirmDialog } from 'primeng/confirmdialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AuthService } from '../services/auth.service';
import { CommandEvent, CommandEventType } from '../services/zoom.service';
import { MediaService } from '../services/media.service';
import { DeviceService } from '../services/device.service';
import { SessionService } from '../services/session.service';
import { NotificationService } from '../services/notification.service'; // Import the new notification service

interface PhotoCapabilities {
  redEyeReduction: boolean | 'never' | 'controllable' | 'always';
  imageHeight: MediaSettingsRange;
  imageWidth: MediaSettingsRange;
  fillLightMode: string[];
}

interface MediaSettingsRange {
  max: number;
  min: number;
  step: number;
}

interface PhotoSettings {
  fillLightMode?: 'auto' | 'off' | 'flash';
  imageHeight?: number;
  imageWidth?: number;
  redEyeReduction?: boolean;
}

interface ConstrainDOMStringParameters {
  exact?: string | string[];
  ideal?: string | string[];
}

interface ConstrainLongParameters {
  exact?: number | number[];
  ideal?: number | number[];
  max?: number;
  min?: number;
}

interface ImageCaptureErrorEventInit extends EventInit {
  error: DOMException;
}

interface ImageCapture {
  new (track: MediaStreamTrack): ImageCapture;
  readonly track: MediaStreamTrack;
  grabFrame(): Promise<ImageBitmap>;
  takePhoto(photoSettings?: PhotoSettings): Promise<Blob>;
  getPhotoCapabilities(): Promise<PhotoCapabilities>;
  getPhotoSettings(): Promise<PhotoSettings>;
  setOptions(photoSettings: PhotoSettings): Promise<void>;
  addEventListener(
    type: 'photo',
    listener: (this: ImageCapture, ev: Event) => any,
    options?: boolean | AddEventListenerOptions
  ): void;
  addEventListener(
    type: 'error',
    listener: (this: ImageCapture, ev: ImageCaptureErrorEvent) => any,
    options?: boolean | AddEventListenerOptions
  ): void;
  removeEventListener(
    type: 'photo',
    listener: (this: ImageCapture, ev: Event) => any,
    options?: boolean | EventListenerOptions
  ): void;
  removeEventListener(
    type: 'error',
    listener: (this: ImageCapture, ev: ImageCaptureErrorEvent) => any,
    options?: boolean | EventListenerOptions
  ): void;
}

declare var ImageCapture: {
  prototype: ImageCapture;
  new (videoTrack: MediaStreamTrack): ImageCapture;
};

interface ImageCaptureErrorEvent extends Event {
  readonly error: DOMException;
}

declare var ImageCaptureErrorEvent: {
  prototype: ImageCaptureErrorEvent;
  new (type: string, eventInitDict: ImageCaptureErrorEventInit): ImageCaptureErrorEvent;
};

interface ImageSettingsInterface {
  quality: number,
  height: number,
  width: number,
  thumbnailHeight: number
}

@Component({
  selector: 'app-inspector-test',
  templateUrl: './inspector-test.component.html',
  styleUrls: ['./inspector-test.component.scss'],
  standalone: true,
  imports: [CommonModule, ButtonModule, ConfirmDialogModule],
})
export class InspectorTestComponent implements OnInit, OnDestroy {
  @ViewChild('inspectorVideo', { static: true }) inspectorVideo!: ElementRef<HTMLVideoElement>;
  // @ViewChild('inspectorVideoSelf', { static: true }) inspectorVideoSelf!: ElementRef;
  // @ViewChild('inspectorLocalVideo', { static: true }) inspectorLocalVideo!: ElementRef<HTMLVideoElement>;
  sessionId: string = "";
  zoomClient: ZoomClientType | void | null = null;
  videoQuality = VideoQuality.Video_1080P;
  sessionEnded = false;
  localVideoStream: MediaStream | null = null;

  constructor(
    private route: ActivatedRoute,
    private zoomService: ZoomService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private authService: AuthService,
    private mediaService: MediaService,
    private deviceService: DeviceService,
    private sessionService: SessionService,
    private notify: NotificationService,
  ) { }

  async test(event: any) {

    let cameraId = await this.getEnvironmentCameraDeviceId();
    if (!cameraId) {
      const cameras: any = await this.deviceService.getVideoDevices(); //stream.getCameraList();
      cameraId = cameras[0]?.deviceId || null;
    }

    if (!cameraId) {
      throw new Error('No suitible camera was found');
    }

    const IMAGE_SETTINGS = {
      quality: 0.85,
      height: this.inspectorVideo.nativeElement.videoHeight,
      width: this.inspectorVideo.nativeElement.videoWidth,
      thumbnailHeight: 78
    }

    if (!this.zoomService.client) {
      throw new Error('Zoom client not initialized');
    }
    const stream = this.zoomService.client.getMediaStream();
    // await stream.stopVideo();

    const mediaStreamConstraints: MediaStreamConstraints = {
      video: {
        deviceId: { exact: cameraId },
        width: { exact: 1080 },
        height: { exact: 1920 },
        frameRate: { ideal: 30 }, 
      }
    };

    const localStream = await window.navigator.mediaDevices
      .getUserMedia(mediaStreamConstraints);

    if (localStream.active) {
      this.inspectorVideo.nativeElement.srcObject = localStream;
      const firstTrack = localStream.getTracks()[0];
      const constraints = firstTrack.getConstraints();
      const settings = firstTrack.getSettings();
      // if ('ImageCapture' in window && 'takePhoto' in ImageCapture.prototype) {
      //   // The ImageCapture API and takePhoto method are supported
      //   console.log('ImageCapture API and takePhoto method are supported.');
      //   const imageCapture = new ImageCapture(firstTrack);
      //   const imageBlob = await imageCapture.takePhoto();
      //   const imageUrl = URL.createObjectURL(imageBlob);
      //   console.log('Image captured:', imageUrl);
      // } else { // The ImageCapture API is not supported
        // Not all required features are supported
        console.log('ImageCapture API or takePhoto method is not supported.');
        
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const contentType = 'image/jpeg';
          
        // let height = (video.videoWidth >= video.videoHeight) ? this.IMAGE_SETTINGS.height : this.IMAGE_SETTINGS.width;
        // let width = (video.videoWidth >= video.videoHeight) ? this.IMAGE_SETTINGS.width : this.IMAGE_SETTINGS.height;
      
        // height = Math.min(height, video.videoHeight);
        // width = Math.min(width, video.videoWidth);
        const video = this.inspectorVideo.nativeElement
        canvas.height = settings.height || 1920;
        canvas.width = settings.width || 1080;
        ctx?.drawImage(video, 0, 0, canvas.width, canvas.height);
        
        const imageUrl: URL = await new Promise((resolve, reject) => {
          canvas.toBlob((blob) => {
            try {
              if (blob) {
                const imageObjectUrl = new URL(URL.createObjectURL(blob)); 
                resolve(imageObjectUrl);
              } else {
                console.error("Capture inspector image failed to convert canvas to blob");
                reject();
              }
            } catch (error: any) {
              console.error("Capture inspector image failed to convert canvas to blob", error);
              reject();
            }
          }, contentType, IMAGE_SETTINGS.quality);
        });
        console.log('Image captured:', URL);
      } 
  }

  async ngOnInit(): Promise<void> {
    this.sessionId = this.route.snapshot.paramMap.get('sessionId') || 'govideo-inspector-test';
    this.zoomClient = await this.zoomService.connect(this.sessionId, 'inspector', 0);
    if (!this.zoomClient) {
      this.notify.showToast('error', 'GoVideo', 'Failed to connect to media network.', 0);
      this.router.navigate(['/contact-us']);
    } else {
      this.setupEventListeners();
      this.notify.showToast('success', 'GoVideo', 'Connected to media network.');
    }
  }

  ngOnDestroy(): void {
    if (!this.sessionEnded) {
      const user = this.zoomClient?.getCurrentUserInfo() || null;
      this.zoomService.leave();
      this.zoomService.destroyClient();
    }
  }


  setupEventListeners() {
    if (!this.zoomService.client) {
      throw new Error('Zoom client not initialized');
    }
    this.zoomService.client.on('peer-video-state-change', (payload: { action: "Start" | "Stop"; userId: number }) => {
      console.log('Peer video state change');
      const participant = this.zoomService?.client?.getUser(payload.userId);
      console.log("Participant:", participant);
      if (payload.action === 'Start') {
        console.log('Peer state change event - Start video');
        if (participant?.userIdentity === "coordinator") {
          // this.cdr.detach();
          // this.startCoordinatorVideo(participant);
          // this.cdr.reattach();
          // this.cdr.detectChanges();
        }
      } else {
        console.log('Peer state change event - Stop video');
        this.notify.showToast('info', 'GoVideo', 'Coordinator has stopped the video');
      }
    });

    this.zoomService.client.on('user-added', (payload: ParticipantPropertiesPayload) => {
      this.notify.showToast('info', 'GoVideo', 'Coordinator has joined the session');
      console.log('Participant joined event :', payload);
    });

    this.zoomService.client.on('user-removed', (payload: ParticipantPropertiesPayload) => {
      this.notify.showToast('info', 'GoVideo', 'Coordinator has left the session');
      console.log('Participant left event:', payload);
    });

    this.zoomService.client.on('session-ended', () => {
      this.sessionEnded = true;
      this.notify.showToast('warn', 'GoVideo', 'Session has ended');
      console.log('Session ended');
    });

    this.zoomService.client.on('error', (error: Error) => {
      console.error('Zoom client error:', error);
      this.notify.showToast('error', 'GoVideo', 'An error occurred while connecting to the media network.', 0);
    });

    this.zoomService.client.on('zoom-sdk-ready', () => {
      console.log('Zoom SDK ready');
      // this.startVideo();
    });

  }

  async startVideo(event: any): Promise<void> {
    try {
      if (!this.zoomClient) {
        throw new Error('Zoom client not initialized');
      }

      const inspectorVideo = this.inspectorVideo.nativeElement;

      if (!inspectorVideo) {
        throw new Error('Video elements not found');
      }
      this.cdr.detach();
      
      console.log('Orientation: ', this.deviceService.orientation$());

      let cameraId = await this.getEnvironmentCameraDeviceId();
      if (!cameraId) {
        const cameras: any = await this.deviceService.getVideoDevices(); //stream.getCameraList();
        // const cameraId = await this.getCameraId();
        // if (!cameraId) {
        //   throw new Error('No cameras available');
        // }
        
        // let cameraId = '';
        cameraId = cameras[0]?.deviceId || null;
        // const cameraId = this.deviceService.isMobile() ? 'environment' : cameras[0]?.deviceId ;
      }

      if (!cameraId) {
        throw new Error('No suitible camera was found');
      }
      // // determine the default camera highest resolution for image capture
      // const localStream = await navigator.mediaDevices.getUserMedia({
      //   video: { deviceId: { exact: cameraId }}//this.deviceService.isMobile() ? 'environment' : cameraId } }
      // });

      // const tracks = localStream.getTracks();
      // const track = tracks[0];
      // const capabilities = track.getCapabilities();
      
      // tracks.forEach((track: MediaStreamTrack) => {
      //   track.stop();
      // });

      
      
      // console.log('Camera Capabilities:', capabilities);
      // const highestResolutions = this.calculateHighestResolution(capabilities);
      // const resolution = highestResolutions.find((resolution: any) => resolution.aspectRatio === '1:1');

      // get camera orientation for proper width height ratio


      let maxWidth = 0;
      let maxHeight = 0;

      // let testStream: MediaStream|null = null;
      // for (const resolution of highestResolutions) {
      //   try {
      //     testStream = await navigator.mediaDevices.getUserMedia({
      //       video: { 
      //         deviceId: { exact: cameraId },
      //         width: { min: resolution.width },
      //         height: { min: resolution.height },
      //       }
      //     });
      //     maxWidth = resolution.width;
      //     maxHeight = resolution.height;
      //     break;
      //   } catch (error) {
      //     console.log('Unable to set resolution:', resolution);
      //     continue;
      //   } finally {
      //     if (testStream) {
      //       testStream?.getTracks().forEach((track: MediaStreamTrack) => track.stop());
      //       testStream = null;
      //     }
      //   }
      // }

      // if resolution is higher than full hd then use full hd resolution
      // if (maxWidth >= 2160 && maxHeight >= 1080) {
      //   maxWidth = 2160;
      //   maxHeight = 1080;
      // }

      // cameraId = '3F92612E55F72D4F41B8FD848BD4AD517CE444AD';

      const maxRes = await this.getMaxResolution(cameraId);

      maxWidth = maxRes.maxWidth; //2160;
      maxHeight = maxRes.maxHeight; //1080;

      const stream = this.zoomClient.getMediaStream();
      if (!stream) {
        throw new Error('Media stream not found');
      }

      // todo: enable audio stream
      // await stream.startAudio();
      const captureVideoOptions: CaptureVideoOption = { 
        fullHd: true,
        originalRatio: true,
        cameraId: cameraId,
      };

      const RenderSeflView = stream.isRenderSelfViewWithVideoElement();
      console.log('Render Self View with video element:', RenderSeflView);
      // If true the use video element else use canvas to render the video

      await stream.startVideo(captureVideoOptions);
      await stream.renderVideo(inspectorVideo, this.zoomClient.getCurrentUserInfo().userId, maxWidth, maxHeight, 0, 0, this.videoQuality);        
      this.cdr.detectChanges();

      this.mediaService.inspectorLocalVideoElement = inspectorVideo;
      this.mediaService.inspectorVideoElement = inspectorVideo;
      console.log('Inspector video and audio started');
    } catch (error: any) {
      if (error.type !== 'INVALID_OPERATION' || error.reason !== 'Video is started') {
        console.error('Error starting inspector video and audio', error);
      } else {
        console.log('Inspector video is already started');
      }
    } finally {
      this.cdr.reattach();
      this.cdr.detectChanges();
    }
  }

  async endSession() {
    this.cdr.detach();
    const user = this.zoomClient?.getCurrentUserInfo() || null;
    await this.zoomService.leave();
    this.zoomService.destroyClient();
    this.sessionEnded = false;
    this.cdr.reattach();
    console.log('Inspector session ended');
    this.router.navigate(['/session-end']);
  }


  calculateHighestResolution(capabilities: any) {
    const { width, height, aspectRatio } = capabilities;
  
    // Standard aspect ratios to consider
    const aspectRatios = [
      { ratio: 16 / 9, label: '16:9' },
      { ratio: 4 / 3, label: '4:3' },
      { ratio: 1, label: '1:1' }
    ];
  
    const highestResolutions: any = [];
  
    aspectRatios.forEach(({ ratio, label }) => {
      // Calculate the maximum possible width and height for this aspect ratio
      let maxWidth = Math.min(width.max, height.max * ratio);
      let maxHeight = Math.min(height.max, width.max / ratio);
  
      // Round down to the nearest integer
      maxWidth = Math.floor(maxWidth);
      maxHeight = Math.floor(maxHeight);
  
      // Check if the aspect ratio is within the capabilities
      const calculatedAspectRatio = maxWidth / maxHeight;
  
      if (
        calculatedAspectRatio >= aspectRatio.min &&
        calculatedAspectRatio <= aspectRatio.max &&
        maxWidth >= width.min &&
        maxHeight >= height.min
      ) {
        highestResolutions.push({
          width: maxWidth,
          height: maxHeight,
          aspectRatio: label
        });
      }
    });
  
    // sort the resolutions from highest to lowest
    highestResolutions.sort((a: any, b: any) => b.width * b.height - a.width * a.height);

    return highestResolutions;
  }

  isMobileDevice() {
    return /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
  }
  
  async getCameraId() {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true });
      const videoDevices = await navigator.mediaDevices.enumerateDevices();
  
      if (this.isMobileDevice()) {
        return 'environment';
      }
  
      const defaultCamera = videoDevices.find((device) => device.kind === 'videoinput');
      return defaultCamera ? defaultCamera.deviceId : null;
    } catch (error) {
      console.error('Error getting cameraId:', error);
      return null;
    }
  }

  async getMaxResolution(cameraId: string) {
    try {
      const constraints = {
        video: {
          deviceId: cameraId,
          width: { ideal: 2160 },
          height: { ideal: 1080 },
        }
      };
  
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      const videoTrack = stream.getVideoTracks()[0];
      const settings = videoTrack.getSettings();
  
      const maxWidth = settings.width || 2160;
      const maxHeight = settings.height || 1080;
  
      videoTrack.stop();
  
      return { maxWidth, maxHeight };
    } catch (error) {
      console.error('Error getting max resolution:', error);
      return { maxWidth: 2160, maxHeight: 1080 };
    }
  }

  async startVideoWithMaxResolution(videoElement: HTMLVideoElement) {
    try {
      const cameraId = await this.getCameraId();
      if (!cameraId) {
        throw new Error('No camera found.');
      }
  
      const { maxWidth, maxHeight } = await this.getMaxResolution(cameraId);
  
      const stream = this.zoomClient?.getMediaStream();
      
      if (stream) {
        await stream.startVideo({
          videoElement: videoElement,
          hd: true,
          cameraId: cameraId,
          captureWidth: maxWidth,
          captureHeight: maxHeight,
        });

        stream.updateSharingCanvasDimension(maxWidth, maxHeight);

      }
    } catch (error) {
      console.error('Error starting video with max resolution:', error);
    }
  }

  async getEnvironmentCameraDeviceId(): Promise<string | null> {
    try {

      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: { exact: 'environment' } },
      });
  
      const track = stream.getVideoTracks()[0];
      const settings = track.getSettings();
      const deviceId = settings.deviceId;
  
      stream.getTracks().forEach((t) => t.stop());

      console.log('Environment Camera Device ID:', deviceId);
      return deviceId || null;
    } catch (err) {
      console.error('Error accessing environment camera:', err);
      return null;
    }
  }

}