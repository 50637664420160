import { Injectable, Injector } from '@angular/core';
import { DeviceService } from './device.service';
import { UploadService } from './upload.service';
import { ZoomService } from './zoom.service';
import { CommandEventType, CommandEvent, CommandChannelResponse } from './zoom.service';
import { Participant } from '@zoom/videosdk';
import { IUploadUrl } from './upload.service';
import { DownloadService, IDownloadUrl } from './download.service';
import { environment } from '../../environments/environment';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SessionService } from './session.service';

interface ImageSettingsInterface {
  quality: number,
  height: number,
  width: number,
  thumbnailHeight: number
}

interface ICaptureIspectorImageResponse {
  blob: Blob,
  imageObjectUrl: URL
}

@Injectable({ 
  providedIn: 'root' 
})
export class MediaService {
  private _coordinatorVideoElement!: HTMLVideoElement;
  private _inspectorVideoElement!: HTMLVideoElement;
  private _inspectorLocalVideoElement!: HTMLVideoElement;
  private IMAGE_SETTINGS!: ImageSettingsInterface;
  private THUMBNAIL_SETTINGS!: ImageSettingsInterface;
  private zoomService!: ZoomService;
  private uploadService!: UploadService;
  private downloadService!: DownloadService;
  private _localVideoStream: MediaStream | null = null;

  apiUrl = `${environment.apiUrl}/media`;

  constructor(
    private deviceService: DeviceService,
    private injector: Injector,
    private http: HttpClient,
    private sessionService: SessionService,
  ) { 
    
    setTimeout(() => {
      this.zoomService = this.injector.get(ZoomService);
      this.uploadService = this.injector.get(UploadService);
      this.downloadService = this.injector.get(DownloadService);
    });

    this.IMAGE_SETTINGS = {
      quality: 0.85,
      height: this.imageHeight(),
      width: this.imageWidth(),
      thumbnailHeight: 78
    }
    
    this.THUMBNAIL_SETTINGS = {
      quality: 0.5,
      height: 96,
      width: 128,
      thumbnailHeight: 96
    }
  }

  get coordinatorVideoElement(): HTMLVideoElement | null {
    return this._coordinatorVideoElement;
  }

  set coordinatorVideoElement(videoElement: HTMLVideoElement) {
    this._coordinatorVideoElement = videoElement;
  }

  set inspectorVideoElement(videoElement: HTMLVideoElement) {
    this._inspectorVideoElement = videoElement;
  }

  get inspectorVideoElement(): HTMLVideoElement | null {
    return this._inspectorVideoElement;
  }

  set inspectorLocalVideoElement(videoElement: HTMLVideoElement) {
    this._inspectorLocalVideoElement = videoElement;
  }

  get inspectorLocalVideoElement(): HTMLVideoElement | null {
    return this._inspectorLocalVideoElement;
  }

  set localVideoStream(stream: MediaStream | null) {
    this._localVideoStream = stream;
  }

  get localVideoStream(): MediaStream | null {
    return this._localVideoStream;
  }

  getImageFilename(sessionId: string): string {
    const timestamp = new Date().getTime();
    return `gv_photo_${sessionId}_${timestamp}.jpg`;
  }
  async capturePhoto(sessionId: string): Promise<any|void> {
    try {
      if (!this._inspectorLocalVideoElement) {
        console.error('No inspector video element found');
        return;
      }

      const coordinator = this.zoomService.client
        ?.getAllUser()
        .find((p) => p.userIdentity === 'coordinator') as Participant;
      // if (!coordinator) {
      //   console.error('No coordinator participant found');
      //   return;
      // }

      let canvas;

      if (this.isAppleDevice()) {
        canvas = await this.computeLocalStreamCanvasForApple(
          this._inspectorLocalVideoElement
        );
      } else {
        const video = this._inspectorVideoElement;

        // let height = (video.videoWidth >= video.videoHeight) ? this.IMAGE_SETTINGS.height : this.IMAGE_SETTINGS.width;
        // let width = (video.videoWidth >= video.videoHeight) ? this.IMAGE_SETTINGS.width : this.IMAGE_SETTINGS.height;

        // height = Math.min(height, video.videoHeight);
        // width = Math.min(width, video.videoWidth);

        canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.height = video.videoHeight;
        canvas.width = video.videoWidth;
        ctx?.drawImage(video, 0, 0, canvas.width, canvas.height);
      }

      const imageFilename = this.getImageFilename(sessionId);
      const contentType = 'image/jpeg';
      const image: ICaptureIspectorImageResponse = await new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
          try {
            if (blob) {
              const imageObjectUrl = new URL(URL.createObjectURL(blob)); 
              resolve({ imageObjectUrl, blob } as ICaptureIspectorImageResponse);
            } else {
              console.error("Capture inspector image failed to convert canvas to blob");
              reject();
            }
          } catch (error: any) {
            console.error("Capture inspector image failed to convert canvas to blob", error);
            reject();
          }
        }, contentType, this.IMAGE_SETTINGS.quality);
      });
  
      if (image) {
        const response = await this.uploadService.uploadPhoto(
          imageFilename,
          sessionId,
          image.blob
        );
        if (response) {
          const mediaFile = await this.saveMedia(response.key, sessionId);
          if (mediaFile) { 
            return mediaFile;
          }
        }
      } else {
        return;
      }
          
    } catch (error: any) {
      console.error('Error capturing inspector image', error);
      throw new Error('Error capturing inspector image from canvas to blob', error);
    }
  }

  imageHeight() : number {
      return this.deviceService.isApplePhone() ? 1080 : 1440;
  }

  imageWidth() : number {
      return this.deviceService.isApplePhone() ? 1440 : 1920;
  }

  async saveMedia(key: string, sessionId: string): Promise<any> {
    try {
      let object: IDownloadUrl | void = await this.downloadService.getOjectDetails(key);
      if (object) {
        const response = await firstValueFrom(
          this.http.post(`${this.apiUrl}`, {
            sessionId,
            name: object.key.split('/').pop(),
            key,
            type: object.type,
            size: object.size,
            url: object.url
          })
        );
        if (response) {
          return response;
        }
      }
    } catch (error: any) {
      console.error('Error saving media', error);
      throw new Error('Error saving media', error);
    }
    return;
  }

  stopLocalMedia() {
    if (this._localVideoStream) {
      this._localVideoStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
  } // Calculate the final video size

  private async computeLocalStreamCanvasForApple(
    videoElement: HTMLVideoElement
  ): Promise<HTMLCanvasElement> {
    return new Promise(async (resolve) => {
      const stream = (await this.zoomService.client?.getMediaStream()) as any;

      // Fade out the video to prevent flash
      videoElement.style.opacity = '0';

      // Capture current frame as placeholder
      const canvas = document.createElement('canvas');
      canvas.classList.add('placeholder-canvas');
      canvas.width = videoElement.videoWidth || videoElement.clientWidth;
      canvas.height = videoElement.videoHeight || videoElement.clientHeight;

      const ctx = canvas.getContext('2d');
      ctx!.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

      videoElement.parentNode!.insertBefore(canvas, videoElement);
      canvas.style.display = 'block';

      // Need to stop Zoom stream because cam cant record and stream at the same time
      await stream.stopVideo();

      const localStream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: { exact: 'environment' }, // Back camera
          width: { ideal: 3840 }, // 4K max width
          height: { ideal: 2160 }, // 4K max height
          frameRate: { ideal: 30 }, // Higher frame rate
        },
      });

      videoElement.srcObject = localStream;

      videoElement.muted = true;
      videoElement.playsInline = true;

      // Play the video to initialize the stream
      await videoElement.play();

      // Update canvas size if necessary
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;

      ctx!.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

      videoElement.srcObject = null;

      // Stop the local stream
      localStream.getTracks().forEach((track) => track.stop());

      // Fade back in and remove the placeholder
      videoElement.style.opacity = '1';
      canvas.style.display = 'none';

      // Clean up placeholder
      videoElement.parentNode!.removeChild(canvas);

      resolve(canvas);

      // Resume Zoom stream
      await stream.startVideo();
    });
  }

  private isAppleDevice(): boolean {
    return /iPhone|iPad|iPod|Macintosh/i.test(navigator.userAgent);
  }
}
